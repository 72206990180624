import React, {SVGProps, VFC} from "react";
import {Icon} from "./Icon";

interface JefLogoProps extends SVGProps<SVGSVGElement> {}

export const JefLogo: VFC<JefLogoProps> = (svgProps) => {
  return (
    <Icon viewBox="0 0 30.388 32" {...svgProps}>
      <g id="j">
        <path
          id="j-fill"
          fill="#edf1f1"
          d="M12.518.888l-.15 19.553a23.951 23.951 0 01-.594 5.815 5.805 5.805 0 01-2.303 3.313 8.845 8.845 0 01-5.124 1.23A23.249 23.249 0 01.4 30.345l.042-5.417a13.972 13.972 0 002.61.302 2.435 2.435 0 002.261-.917 8.78 8.78 0 00.58-3.872L6.042.888h6.476z"
        />
        <path
          id="j-outline"
          fill="#010202"
          d="M13.597 1.4l-.793-.796V.603l-.002-.001a.395.395 0 00-.28-.117H6.044a.405.405 0 00-.403.403l-.15 19.553c-.018 2.35-.293 3.29-.522 3.665-.289.48-.935.721-1.914.721a13.734 13.734 0 01-2.534-.294.374.374 0 00-.077-.007.41.41 0 00-.257.094.4.4 0 00-.147.309L0 30.345a.4.4 0 00.17.326.424.424 0 01-.053-.039l.794.798a.39.39 0 00.208.11 23.82 23.82 0 004.019.46 9.265 9.265 0 005.359-1.305 6.239 6.239 0 002.46-3.54 24.446 24.446 0 00.606-5.916l.15-19.553a.4.4 0 00-.116-.286zM12.85.662l-.013-.016zm.06.145a.317.317 0 00-.01-.035.317.317 0 01.01.035zM12.888.73l-.012-.023zM.442 24.929a13.974 13.974 0 002.611.301 2.434 2.434 0 002.26-.916 8.778 8.778 0 00.58-3.873L6.043.888h6.475l-.15 19.553a23.951 23.951 0 01-.594 5.815 5.805 5.805 0 01-2.303 3.313 8.845 8.845 0 01-5.124 1.23A23.249 23.249 0 01.4 30.345zm-.24 5.766a.383.383 0 00.123.046.388.388 0 01-.123-.046z"
        />
      </g>
      <g id="f">
        <path
          id="f-fill"
          fill="#edf1f1"
          d="M25.244.4a23.231 23.231 0 013.946.454l-.04 5.417a14.133 14.133 0 00-2.61-.302 2.445 2.445 0 00-2.262.917 8.791 8.791 0 00-.58 3.875l-.02 2.717h2.449l-.037 4.77H23.64l-.092 12.064h-6.473l.149-19.551a23.968 23.968 0 01.595-5.817 5.805 5.805 0 012.3-3.313 8.843 8.843 0 015.124-1.23z"
        />
        <path
          id="f-outline"
          fill="#010202"
          d="M30.272 1.369L29.477.57l.016.02a.403.403 0 00-.227-.132A23.804 23.804 0 0025.247 0a9.25 9.25 0 00-5.356 1.305 6.201 6.201 0 00-2.46 3.538 24.422 24.422 0 00-.61 5.918l-.149 19.55a.397.397 0 00.135.3l-.018-.014.794.798a.398.398 0 00.284.118h6.474a.407.407 0 00.406-.403l.089-11.661h2.046a.405.405 0 00.403-.403l.037-4.77a.399.399 0 00-.117-.286l-.794-.798v.001a.395.395 0 00-.281-.118h-1.246l.012-1.516c.017-2.352.292-3.293.52-3.667.29-.478.934-.721 1.916-.721a13.706 13.706 0 012.531.294.416.416 0 00.078.007.407.407 0 00.405-.402l.042-5.417a.4.4 0 00-.116-.284zM29.566.71a.394.394 0 00-.059-.102.395.395 0 01.06.102zm.027.144a.394.394 0 00-.021-.126.394.394 0 01.021.126zM26.47 13.272a.427.427 0 01.026.049.414.414 0 00-.026-.05zm.036.08a.362.362 0 01.013.045.389.389 0 00-.013-.045zm-.046-.1a.43.43 0 00-.035-.043.423.423 0 01.035.043zm-1.58.226h1.246l-.036 4.77H23.64l-.092 12.064h-6.473l.149-19.551a23.97 23.97 0 01.595-5.817 5.805 5.805 0 012.3-3.313 8.844 8.844 0 015.124-1.23 23.231 23.231 0 013.946.453l-.04 5.417a14.133 14.133 0 00-2.61-.302 2.445 2.445 0 00-2.262.917 8.791 8.791 0 00-.58 3.875l-.02 2.717h.807"
        />
      </g>
      <g id="amp">
        <path
          id="amp-fill"
          d="M13.955 21.917a3.568 3.568 0 01-2.838-1.053 3.61 3.61 0 01-.898-2.423 3.556 3.556 0 012.413-3.286 4.408 4.408 0 01-.926-2.401 2.467 2.467 0 01.882-1.885 3.239 3.239 0 012.244-.787 3.018 3.018 0 012.218.823 2.76 2.76 0 01.827 2.028 3.093 3.093 0 01-1.817 2.73l1.208 1.605a8.611 8.611 0 00.689-2.11.202.202 0 01.197-.16.204.204 0 01.036.003l1.812.324a.202.202 0 01.16.244 10.749 10.749 0 01-1.448 3.478 1.32 1.32 0 00.87.427q.07 0 .37-.016h.011a.202.202 0 01.202.201v1.885a.202.202 0 01-.152.196 3.703 3.703 0 01-.894.12 3.082 3.082 0 01-2.17-.98 4.839 4.839 0 01-2.996 1.037zm-.14-5.122a1.755 1.755 0 00-.877 1.5 1.411 1.411 0 00.36 1.007 1.314 1.314 0 001.022.382 1.91 1.91 0 001.281-.474zm1.138-4.725a.637.637 0 00-.493.18.68.68 0 00-.174.487 2.634 2.634 0 00.585 1.323 1.442 1.442 0 00.742-1.258.83.83 0 00-.172-.536.578.578 0 00-.488-.196z"
          fill="#edf0f1"
        />
        <path
          id="amp-outline"
          fill="#010202"
          d="M14.832 10.284a2.812 2.812 0 012.075.764 2.554 2.554 0 01.768 1.885 2.984 2.984 0 01-1.917 2.664l1.536 2.04a7.855 7.855 0 00.86-2.438l1.812.325a10.46 10.46 0 01-1.51 3.55 1.62 1.62 0 001.129.602q.072 0 .381-.017v1.885a3.498 3.498 0 01-.845.114 2.954 2.954 0 01-2.153-1.049 4.655 4.655 0 01-3.013 1.105 3.366 3.366 0 01-2.686-.983 3.397 3.397 0 01-.848-2.29q0-2.137 2.518-3.202a4.433 4.433 0 01-1.032-2.486 2.264 2.264 0 01.816-1.734 3.032 3.032 0 012.109-.735m-.017 4.046a1.702 1.702 0 001-1.528 1.034 1.034 0 00-.216-.662.773.773 0 00-.645-.272.835.835 0 00-.642.244.88.88 0 00-.228.625 3.054 3.054 0 00.731 1.593m-.495 5.556a2.153 2.153 0 001.552-.65l-2.007-2.713a2.024 2.024 0 00-1.129 1.771 1.619 1.619 0 00.414 1.146 1.516 1.516 0 001.17.446m.512-10.005a3.435 3.435 0 00-2.379.839 2.662 2.662 0 00-.95 2.034 4.322 4.322 0 00.831 2.32 3.665 3.665 0 00-2.317 3.367 3.806 3.806 0 00.947 2.555 3.759 3.759 0 002.99 1.123 5.015 5.015 0 002.983-.976 3.21 3.21 0 002.184.919 3.903 3.903 0 00.943-.126.404.404 0 00.306-.392V19.66a.403.403 0 00-.404-.403h-.021c-.27.015-.342.016-.36.016a.998.998 0 01-.607-.263 10.985 10.985 0 001.382-3.395.404.404 0 00-.322-.487l-1.812-.325a.405.405 0 00-.466.314 9.377 9.377 0 01-.531 1.764l-.872-1.158a3.201 3.201 0 001.722-2.789 2.954 2.954 0 00-.887-2.17 3.21 3.21 0 00-2.36-.882zm.09 3.895a2.092 2.092 0 01-.434-1.039.471.471 0 01.12-.35.423.423 0 01.346-.115c.235 0 .303.086.329.118a.638.638 0 01.129.412 1.183 1.183 0 01-.49.974zm-.602 5.707a1.124 1.124 0 01-.874-.318 1.217 1.217 0 01-.307-.87 1.502 1.502 0 01.633-1.218l1.55 2.094a1.7 1.7 0 01-1.002.312z"
        />
      </g>
    </Icon>
  );
};
