import React, {SVGProps, VFC} from "react";
import {Icon} from "./Icon";

interface TypescriptLogoProps extends SVGProps<SVGSVGElement> {}

export const TypescriptLogo: VFC<TypescriptLogoProps> = (svgProps) => {
  return (
    <Icon viewBox="0 0 32 32" {...svgProps}>
      <rect width="32" height="32" rx="3.13" fill="#3178c6" />
      <path
        d="M19.8 25.46v3.13a6.63 6.63 0 001.81.59 11.89 11.89 0 002.2.2 10.26 10.26 0 002.14-.22 5.24 5.24 0 001.77-.7 3.61 3.61 0 001.21-1.3 4.55 4.55 0 00.2-3.46 3.48 3.48 0 00-.74-1.14 5.37 5.37 0 00-1.15-.9 12.31 12.31 0 00-1.51-.74q-.62-.26-1.1-.5a5.61 5.61 0 01-.84-.49 2.13 2.13 0 01-.53-.52 1.11 1.11 0 01-.18-.63 1.05 1.05 0 01.16-.59 1.44 1.44 0 01.47-.44 2.51 2.51 0 01.74-.29 4.31 4.31 0 01.98-.1 6.2 6.2 0 01.83.06 6.54 6.54 0 01.88.18 6.1 6.1 0 01.85.3 4.72 4.72 0 01.77.44V15.4a7.64 7.64 0 00-1.56-.4 12.71 12.71 0 00-1.94-.14 9.03 9.03 0 00-2.12.24 5.37 5.37 0 00-1.75.75 3.74 3.74 0 00-1.2 1.3 3.8 3.8 0 00-.44 1.88 3.65 3.65 0 00.8 2.39 6.14 6.14 0 002.46 1.67q.64.26 1.2.52a6.7 6.7 0 01.96.52 2.53 2.53 0 01.64.6 1.21 1.21 0 01.24.73 1.12 1.12 0 01-.15.57 1.3 1.3 0 01-.44.45 2.44 2.44 0 01-.75.3 4.47 4.47 0 01-1.04.1 5.94 5.94 0 01-2.01-.36 6.01 6.01 0 01-1.85-1.07zm-5.25-7.7h4.01v-2.57H7.38v2.57h3.99v11.43h3.18z"
        fill="#fff"
      />
    </Icon>
  );
};
