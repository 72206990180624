import React, {SVGProps, VFC} from "react";
import {Icon} from "./Icon";

interface PostgresLogoProps extends SVGProps<SVGSVGElement> {}

export const PostgresLogo: VFC<PostgresLogoProps> = (svgProps) => {
  return (
    <Icon viewBox="0 0 432.1 445.4" {...svgProps}>
      <g
        fill="none"
        stroke="#fff"
        strokeWidth="12.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M323.2 324.2c2.8-23.6 2-27 19.6-23.2l4.4.4c13.5.6 31.2-2.2 41.6-7 22.4-10.4 35.6-27.7 13.6-23.2-50.3 10.4-53.8-6.6-53.8-6.6 53.1-78.8 75.3-178.9 56.2-203.3C352.5-5.5 262 26 260.5 26.9h-.5c-9.9-2-21-3.2-33.5-3.4-22.8-.4-40 6-53.1 15.9 0 0-161.5-66.5-154 83.6 1.7 32 45.8 241.6 98.5 178.3 19.3-23.2 37.9-42.7 37.9-42.7a48.9 48.9 0 0032 8.1l.8-.8a35 35 0 00.4 9c-13.6 15.2-9.6 17.9-36.8 23.5-27.4 5.6-11.3 15.7-.8 18.3 12.8 3.2 42.3 7.8 62.3-20.2l-.8 3.2c5.3 4.3 5 30.6 5.7 49.5.8 18.8 2 36.4 5.9 46.7 3.8 10.4 8.4 37 44 29.4 29.8-6.4 52.6-15.5 54.7-101"
          fill="#000"
          stroke="#000"
          strokeWidth="37.4"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <path
          d="M402.4 271.2c-50.3 10.4-53.8-6.6-53.8-6.6 53.1-78.8 75.3-178.9 56.2-203.4C352.5-5.5 262 26 260.5 27h-.5c-9.9-2-21-3.2-33.5-3.4-22.8-.4-40 6-53.1 15.9 0 0-161.5-66.5-154 83.6C21 155 65.2 364.6 118 301.3a973.2 973.2 0 0137.9-42.7 48.9 48.9 0 0031.9 8.1l.9-.8c-.3 3-.2 5.7.4 9-13.6 15.2-9.6 17.9-36.8 23.5-27.4 5.6-11.3 15.7-.8 18.3 12.8 3.2 42.3 7.8 62.3-20.2l-.8 3.2c5.3 4.3 9 27.7 8.4 49-.6 21.2-1 35.8 3.2 47.2 4.2 11.4 8.4 37 44 29.4 29.8-6.4 45.3-23 47.4-50.5 1.6-19.7 5-16.8 5.2-34.3l2.8-8.3c3.2-26.6.5-35.2 18.9-31.2l4.4.4c13.5.6 31.2-2.2 41.6-7 22.4-10.4 35.6-27.7 13.6-23.2z"
          fill="#336791"
          stroke="none"
        />
        <path d="M215.9 286.5c-1.4 49.5.3 99.4 5.2 111.5 4.8 12.1 15.2 35.7 50.9 28 29.8-6.4 40.6-18.7 45.3-46 3.5-20.1 10.2-75.9 11-87.3M173.1 38.3s-161.5-66-154 84c1.6 32 45.8 241.7 98.5 178.4 19.2-23.2 36.6-41.4 36.6-41.4M260.3 26.2c-5.5 1.8 89.9-34.9 144.1 34.4 19.2 24.5-3 124.5-56.1 203.4" />
        <path
          d="M348.3 264s3.4 17 53.7 6.6c22-4.5 8.8 12.8-13.5 23.2-18.4 8.5-59.5 10.7-60.2-1.1-1.7-30.4 21.7-21.1 20-28.7-1.5-6.9-12-13.6-19-30.4-6-14.6-82.7-126.8 21.4-110.2 3.8-.8-27.2-99-124.6-100.6-97.4-1.6-94.2 119.8-94.2 119.8"
          strokeLinejoin="bevel"
        />
        <path d="M188.6 274.3c-13.6 15.2-9.6 17.9-36.7 23.5-27.5 5.6-11.3 15.7-.8 18.3 12.8 3.2 42.3 7.7 62.3-20.2 6-8.5 0-22.1-8.4-25.6-4-1.6-9.5-3.7-16.4 4z" />
        <path d="M187.7 274c-1.4-8.8 3-19.5 7.6-31.9 6.9-18.6 22.8-37.2 10-96.3-9.5-44-73.3-9.2-73.4-3.2 0 6 3 30.3-1 58.5-5.2 37 23.5 68.2 56.4 65" />
        <path
          d="M172.5 141.7c-.3 2 3.7 7.5 9 8.2 5.2.7 9.7-3.5 10-5.6.3-2-3.7-4.2-9-5-5.2-.7-9.7.4-10 2.4z"
          fill="#fff"
          strokeWidth="4.2"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <path
          d="M332 137.5c.2 2-3.8 7.5-9 8.3-5.3.7-9.8-3.6-10-5.6-.3-2 3.7-4.3 9-5 5.2-.8 9.7.3 10 2.3z"
          fill="#fff"
          strokeWidth="2.1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <path d="M350.7 123.4c.8 16-3.5 27-4 44-.8 24.7 11.8 53-7.2 81.4" />
      </g>
    </Icon>
  );
};
